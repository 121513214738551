<template>
  <div v-can="'RepMedConf1'">
    <section class="header">
      <div class="title">Configurações</div>
    </section>

    <section>
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <label for="payment_type">
              Pagamento do repasse
            </label>
            <multiselect
              v-can="'RepMedConf2'"
              v-model="form.payment_type"
              placeholder="Selecionar"
              :options="paymentTypes"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              @select="changePaymentType"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option }}
              </template>

              <template slot="noOptions">
                Nenhuma opção
              </template>

              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div v-if="validated && !form.payment_type" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label for="payment_type">
              Cálculo do repasse
              <span id="help-icon" class="icon-box">
                <HelpCircle class="icon stroke" />
              </span>
              <b-tooltip target="help-icon" placement="bottom">
                Defina o cálculo que o sistema utilizará <br/>
                para repassar o valor dos itens aos favorecidos<br/>
                <b> 
                  A validade do cálculo será atribuída <br/>
                  aos itens a partir da alteração em diante.
                </b>
              </b-tooltip>
            </label>
            <multiselect
              v-can="'RepMedConf2'"
              v-model="form.value_type"
              placeholder="Selecionar"
              :options="valueTypes"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              label="label" 
              track-by="value" 
              class="with-border"
              @select="changeValueType"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>

              <template slot="noOptions">
                Nenhuma opção
              </template>

              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div v-if="validated && !form.value_type" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label for="enable_value">
              Habilitar Porcentagem
              <span id="help-icon-porc" class="icon-box">
                <HelpCircle class="icon stroke" />
              </span>
              <b-tooltip target="help-icon-porc" placement="bottom">
                Define se a porcentagem sera exibida na<br/>
                impressão de extrato de repasse <br/>
              </b-tooltip>
            </label>
            <multiselect
              v-can="'RepMedConf2'"
              v-model="form.enable_value"
              placeholder="Selecionar"
              :options="enableValues"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              label="label" 
              track-by="newValue" 
              class="with-border"
              @select="changeEnableValue"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>

              <template slot="noOptions">
                Nenhuma opção
              </template>

              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div v-if="validated && !form.enable_value" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Configuration',
  metaInfo: {
    title: 'Eyecare - Repasse médico - Configurações'
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    HelpCircle : () => import('@/assets/icons/lightblue-help-circle.svg'),
  },
  async created() {
    const isLoading = this.$loading.show()
    await this.getConfigurations()
    isLoading.hide()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      validated: false,
      form: {},
      paymentTypes: [
        'Á vista',
        'Parcelado'
      ],
      valueTypes: [
        {
          label: 'Valor bruto',
          value: 'GROSS',
        },
        {
          label: 'Valor líquido',
          value: 'LIQUID',
        }
      ],
      enableValues: [
        {
          label: 'Sim',
          newValue: 'SIM',
        },
        {
          label: 'Não',
          newValue: 'NÃO',
        }
      ]
    }
  },
  methods: {
    async getConfigurations() {
      try {
        const response = await api.getOnlendinClinicSettingsByClinicId(this.clinic.id)
        this.form = this.getDefaultForm(response.data)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },

    getDefaultForm (data) {
      return {
        id: data.id ? data.id : null,
        payment_type: data.payment_type ? data.payment_type : null,
        value_type: data.value_type ? this.valueTypes.find(option => option.value === data.value_type) : null,
        enable_value: data.enable_value ? this.enableValues.find(option => option.newValue === data.enable_value) : null
      }
    },

    changePaymentType(e){
      this.form.payment_type = e
      this.handleChange()
    },
    changeValueType(e){
      this.form.value_type = e
      this.handleChange()
    },
    changeEnableValue(e){
      this.form.enable_value = e
      this.handleChange()
    },
    handleChange(){
      this.validated = true
      if(!this.form.payment_type || !this.form.value_type || !this.form.enable_value) return;

      if(this.form.id) this.updateConfiguration()
      else this.createConfiguration()
    },
    
    async createConfiguration() {
      try {
        const payload = this.form
        payload.value_type = payload.value_type.value
        payload.enable_value = payload.enable_value.newValue
        await api.createOnlendinClinicSettings({
          ...payload,
          clinic_id: this.clinic.id
        })
        this.$toast.success('Configuração cadastrada com sucesso')
        this.getConfigurations()
        this.$emit('createConfig')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateConfiguration() {
      try {
        const payload = this.form
        payload.value_type = payload.value_type.value
        payload.enable_value = payload.enable_value.newValue
        await api.updateOnlendinClinicSettings(
          this.form.id,
          payload
        )
        this.$toast.success('Configuração atualizada com sucesso')
        this.getConfigurations()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--type-active);
    display: flex;
    align-items: center;
  }
}

.alert{
  stroke: var(--orange);
}
</style>